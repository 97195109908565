<template>
  <div
    ref="wrapper"
    class="product-distributor"
  >
    <dealer-information
      style="margin-bottom: 2rem"
      :list="maintenanceDistribution"
    />
    <service-details :list="maintenanceDistribution" />
  </div>
</template>

<script>
import DealerInformation from './components/DealerInformation'
import ServiceDetails from './components/ServiceDetails'
import { getMaintenanceDistribution } from '../../api/api'

export default {
  name: 'NewProductDistributor',
  components: {
    DealerInformation,
    ServiceDetails
  },
  data () {
    return {
      maintenanceDistribution: {},
      ProductId: ''
    }
  },
  mounted () {
    this.ProductId = this.$route.query && this.$route.query.CustProdId
    this._getMaintenanceDistribution()
  },
  methods: {
    _getMaintenanceDistribution () {
      const { ProductId } = this
      getMaintenanceDistribution({
        ProductId
      }).then(res => {
        this.maintenanceDistribution = res.data.Data
        // console.log(res.data.Data)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.product-distributor {
    @media screen and (max-width:767px) {
      // margin-top: -3rem;
    }
}
</style>
