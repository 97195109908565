<template>
  <div class="content">
    <el-card>
      <div class="title">
        <div class="title_left">
          <h1>
            {{
              nameId === '001'
                ? (name = '專人到府濾心更換')
                : (name = '到店取貨付款')
            }}
          </h1>
          <!-- <span>On-site service deta</span> -->
        </div>
        <div
          class="title_right"
          @click="
            $router.push({
              path: '/toproduct',
              query: { custProdId: details.CustProdId }
            })
          "
        >
          返回產品內容頁<i class="el-icon-arrow-right" />
        </div>
      </div>
      <div
        ref="refSuccess"
        class="list"
      >
        <el-row
          v-for="(item, index) in details.Detail"
          :key="index"
        >
          <el-col
            :xs="7"
            :sm="7"
            :md="4"
            :lg="4"
            @click.stop="isShowClick(item)"
          >
            <span class="input-checkbox">
              <label>
                <input
                  v-model="getCheckboxs"
                  type="checkbox"
                  class="remmber"
                  :value="item.Sequence"
                >
                <span />
                第{{ item.Sequence }}道濾心
              </label>
              <!-- <span><i class="mcicon-4" ref="refSuccess" @click="isShowClick(index,item.Sequence)" :class="{'mcicon-5': radios[isIndex].isChecked}"></i></span> -->
            </span>
          </el-col>
          <el-col
            :xs="11"
            :sm="9"
            :md="6"
            :lg="6"
          >
            <div class="item">
              <img
                src="./../../../assets/img/icon_1@2x.png"
                alt=""
              >
              <div class="name">
                <span>{{ item.FilterDescription }}</span>
                <span>{{ item.FilterName }}</span>
              </div>
            </div>
          </el-col>
          <el-col
            :xs="6"
            :sm="8"
            :md="5"
            :lg="5"
          >
            <div
              class="btn"
              :class="{
                red: item.RemainLife === '0%' || item.RemainLife === '0'
              }"
            >
              <div
                class="bg_color"
                :style="'width:' + item.RemainLife"
              />
              <span v-if="item.RemainLife > '0%'">
                壽命：{{ item.RemainLife }}
              </span>
              <span v-else>
                已過期
              </span>
            </div>
          </el-col>
          <el-col
            :xs="12"
            :sm="12"
            :md="5"
            :lg="5"
          >
            <div class="price">
              建議售價：{{ item.FilterUniPrice }}元
            </div>
          </el-col>
          <el-col
            :xs="12"
            :sm="12"
            :md="4"
            :lg="4"
          >
            <div class="amount">
              數量
              <!-- <span>13</span> -->
              <!-- <input type="text" v-model.number="num[index].i" maxlength="2"> -->
              <select v-model.number="num[index].i">
                <option
                  v-for="(item, index) in numList"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </el-col>
        </el-row>

        <!--       <el-row>
          <el-col :xs="7" :sm="7" :md="4" :lg="4">
            <span>
              <i class="el-icon-success" @click="GuideClick"></i>
              第一道濾心
            </span>
          </el-col>
          <el-col :xs="11" :sm="9" :md="6" :lg="6">
            <div class="item">
              <img src="./../../../assets/img/icon_1@2x.png" alt="">
              <span>複合式濾心</span>
              <span>F0161</span>
            </div>
          </el-col>
          <el-col :xs="6" :sm="8" :md="5" :lg="5">
            <div class="btn" :class="{'red': details.Detail[0].RemainLife}">
              <div class="bg_color">
              </div>
                 <span>
                   123
               {{details.Detail[0].RemainLife}}
              </span>

            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="5" :lg="5">
            <div class="price">
               建議售價：500元
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="4" :lg="4">
            <div class="amount">
              数量
              <span>13</span>
            </div>
          </el-col>
        </el-row>
 -->
      </div>

      <div class="bottom">
        <el-row class="price-row">
          <el-col
            :md="24"
            :lg="18"
          >
            價格總計：
          </el-col>
          <el-col
            :xs="8"
            :sm="8"
            :md="6"
            :lg="6"
          >
            {{ _getCalcPrice() }}
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col
            :md="24"
            :lg="18"
          >
            <el-row :gutter="10">
              <el-col
                :sm="24"
                :md="12"
                :lg="12"
                class="row_col"
              >
                <div class="tiem">
                  <span class="tex">{{
                    nameId === '001'
                      ? (title = '預約服務日期')
                      : (title = '取貨付款日期')
                  }}</span>
                  <div class="tiems">
                    <!-- <span>2020.03.05</span> -->
                    <myDatepicker
                      :date="startTime"
                      :option="option"
                      :limit="limit"
                    />
                    <i class="el-icon-arrow-down" />
                  </div>
                </div>
              </el-col>
              <!-- <el-col :sm="24" :md="12" :lg="12" v-if="false">
                 <div class="tiem">
                  <span class="tex">專人到府服務時間</span>

                  <div class="tiems">

                    <span>2020.03.05</span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </el-col> -->
            </el-row>
          </el-col>
          <el-col
            :xs="8"
            :sm="8"
            :md="6"
            :lg="6"
            class="bottom_btn"
          >
            <div
              class="btn"
              @click="clickChange"
            >
              <span>{{ btnName }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <!-- <guide-one
      v-show="isShow"
      :btn="clickBtn"
      :p-left="getLeft"
      :p-bottom="getBottom"
    /> -->
    <!-- 提交成功 -->
    <guide-two
      :is-dialog-show="isShowTwo"
      :no-show-click="noShowClick"
      mody-type="1"
    />
    <!-- 修改成功 -->
    <guide-two
      :is-dialog-show="isShowTwo2"
      :no-show-click="_noShowClick"
      mody-type="2"
    />
    <!-- 尚未選擇時間 -->
    <guide-two
      :is-dialog-show="isShowTwo3"
      :no-show-click="noWarningClick"
      mody-type="3"
    />
    <!-- 通用錯誤處理 -->
    <guide-two
      :is-dialog-show="isShowError"
      :no-show-click="closeHolidayClick"
      :mody-type="modyType"
    />
    <guide-two
      :is-dialog-show="isFilterShowTwo"
      :no-show-click="filterClick"
      :filter_="true"
    />
    <guide-two
      :is-dialog-show="isFilterShowWarning"
      :no-show-click="isFilterWarningClick"
      :warning="true"
    />
    <guide-three
      v-show="isShowThree"
      :btn-left="BtnLeft"
    />
    <Loading :show="isShowLoadging" />
  </div>
</template>

<script>
// import GuideOne from '@/components/PopUp/Guide_1'
import GuideTwo from '@/components/PopUp/Guide_2'
import GuideThree from '@/components/PopUp/Guide_3'
import {
  getByProductId,
  UpdateCustomerTeachingBuy2Filter,
  getCustomerByLineMid
} from '@/api/api'
// , getCreateFilterOrder
import myDatepicker from 'vue-datepicker'
import moment from 'moment'

import storage from '@/storage'
import { newTime, NewTimeAddDays } from '@/util'
import { post as ajaxPost } from './../../../plugins/ajax'
import { Holiday } from '@/util/holiday'

export default {
  name: 'ServicDetails',
  components: {
    // GuideOne,
    GuideThree,
    GuideTwo,
    myDatepicker
  },
  props: {
    list: { type: Object }
  },
  data () {
    return {
      ModelType: '',
      isShowLoadging: true,
      isShow: false,
      getLeft: 0,
      getBottom: 0,
      isShowTwo: false,
      isShowTwo2: false,
      isShowTwo3: false,
      isFilterShowTwo: false,
      isShowThree: false,
      isShowError: false,
      isFilterShowWarning: false,
      fullWidth: document.documentElement.clientWidth,
      isIndex: 0,
      CustProdId: '',
      details: [],
      nameId: '',
      name: '',
      title: '',
      getCheckboxs: [],
      visible: false,
      OrderDetail: [],
      numList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      // num: [{ i: 0 }, { i: 0 }, { i: 0 }],
      num: [],
      flag: false,
      newTime: newTime(),
      startTime: {
        // 相當於變數
        time: ''
      },
      dateTime: '',
      option: {
        type: 'day',
        week: ['週一', '週二', '週三', '週四', '週五', '週六', '週日'],
        month: [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月'
        ],
        format: 'YYYY-MM-DD',
        inputStyle: {
          display: 'inline-block',
          // padding: '6px',
          'line-height': '22px',
          width: '100%',
          'font-size': '14px',
          border: 'none',
          // border: '2px solid #fff',
          // 'box-shadow': '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
          // 'border-radius': '2px',
          outline: 'none',
          color: '#5F5F5F',
          margin: '0'
        },
        color: {
          // 字體顏色
          header: '#35acff', // 頭部
          headerText: '#fff' // 頭部文案
        },
        buttons: {
          // button 文案
          // ok: '確定',
          cancel: '取消'
        },
        placeholder: '請選時間',
        dismissible: true
      },
      limit: [],
      btnName: '確定',
      userName: {},
      btnNameType: '1',
      modyType: '0'
    }
  },
  watch: {
    isShow (newVal, oldVal) {
      // if (newVal === true) {
      //   console.log('gaibian l ')
      //   const cssStr = 'overflow-y: hidden; height: 100%;'
      //   document.getElementsByTagName('html')[0].style.cssText = cssStr
      //   document.body.style.cssText = cssStr
      // } else {
      //   const cssStr = 'overflow-y: auto; height: auto;'
      //   document.getElementsByTagName('html')[0].style.cssText = cssStr
      //   document.body.style.cssText = cssStr
      // }
      // // 下麵需要這兩行程式碼，相容不同瀏覽器
      // document.body.scrollTop = this.pageScrollYoffset
      // window.scroll(0, this.pageScrollYoffset)
    },
    getCheckboxs (newVal, oldVal) {
      this.num.forEach((obj, idx) => {
        if (newVal.includes((idx + 1).toString()) && obj.i === 0) {
          obj.i = 1
        } else if (!newVal.includes((idx + 1).toString())) {
          obj.i = 0
        }
      })
    }
  },
  async mounted () {
    window.addEventListener('resize', this.handleResize)
    this.CustProdId = this.$route.query && this.$route.query.CustProdId
    this.nameId = this.$route.query && this.$route.query.name
    this._getByProductId()
    this.$nextTick(() => {
      setTimeout(() => {}, 100)
      this.getOffsetTop()
    })
    this.userName = storage.getItem('userName')
    const time = new Date()
    this.dateTime = time.toLocaleDateString()

    this.limit = await (async () => {
      // const response = await axios.get('https://www.googleapis.com/calendar/v3/calendars/zh-tw.taiwan%23holiday%40group.v.calendar.google.com/events?key=AIzaSyDcbQIcvb36_bzJsFI_8gXmmBmVgB7wQo8')
      // const holidayList = response.data.items.map(o => ({ type: 'fromto', from: o.start.date, to: o.start.date }))
      // const notHolidayList = []
      // const nowMoment = moment(moment().format('YYYY-MM-DD'))
      // for (let i = 0; i < 365; i++) {
      //   const nowFormat = nowMoment.format('YYYY-MM-DD')
      //   if (!holidayList.find(o => o.from === nowFormat)) {
      //     notHolidayList.push({
      //       type: 'fromto',
      //       from: nowFormat,
      //       to: moment(nowFormat).add(2, 'day').format('YYYY-MM-DD')
      //     })
      //   }
      //   nowMoment.add(1, 'day')
      // }
      // console.log('list => ', notHolidayList)
      return [
        {
          type: 'weekday',
          available: [1, 2, 3, 4, 5, 6]
        },
        {
          type: 'fromto',
          from: NewTimeAddDays(7),
          to: NewTimeAddDays(90)
        }
      ]
    })()
  },
  methods: {
    /*  isShows () {
      this.getOffsetTop()
      this.isShow = true

      const mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.removeEventListener('touchmove', mo, false)
    }, */
    _getCalcPrice () {
      return (this.details.Detail || [])
        .map((o, i) => ({
          index: i,
          price:
            parseInt(o.FilterUniPrice || 0, 10) * (this.num[i].i || 0) || 0,
          Sequence: o.Sequence
        }))
        .filter(o => this.getCheckboxs.includes(o.Sequence))
        .map(o => o.price)
        .reduce((a, b) => a + b, 0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    _getByProductId () {
      const { CustProdId } = this
      getByProductId({
        CustProdId
      }).then(res => {
        this.details = res.data.Data[0]
        this.ModelType = res.data.Data[0].ModelTypeName
        res.data.Data[0].Detail.forEach(item => {
          this.num.push({
            i: 0
          })
        })
        // const { IsOldWaterProduct } = this.details
        // if (!IsOldWaterProduct) {
        this.isShowLoadging = false
        // this.noScroll()
        // }
        const userInfo = storage.getItem('userName')
        if (!userInfo.Teaching_DT_Buy2_Filter) {
          this.isShow = true
          this.noScroll()
        }
      })
    },
    clickBtn () {
      UpdateCustomerTeachingBuy2Filter({
        LINEMid: storage.getItem('LINEMid')
      }).then(res => {
        this.isShow = false
        this.canScroll()
        const mo = function (e) {
          e.preventDefault()
        }
        document.body.style.overflow = ''
        document.removeEventListener('touchmove', mo, false)
        getCustomerByLineMid({
          LINEMid: storage.getItem('LINEMid')
        }).then(res => {
          if (res.status === 200) {
            this.userName = res.data.Data
            storage.setItem('userName', res.data.Data)
          }
        })
      })
    },

    filterClick () {
      this.isFilterShowTwo = false
      this.$router.push({ name: 'MatchingComplete' })
    },
    GuideClick () {
      this.isShowThree = false
      this.canScroll()
    },
    BtnLeft () {
      this.isShowThree = false
      this.canScroll()
    },
    getOffsetTop () {
      // const { left, bottom } = this.$refs.refSuccess.getBoundingClientRect()
      // if (this.fullWidth < 768) {
      //   this.getLeft = left - 8 + 10
      //   this.getBottom = -(bottom - 136)
      // } else {
      //   this.getLeft = left - 8 + 10
      //   this.getBottom = -(bottom - 163)
      // }
    },
    // 提交
    async clickChange () {
      const dateString = moment(this.startTime.time).format('YYYYMMDD')
      if (Holiday.holidayList.includes(dateString)) {
        this.modyType = '16'
        this.isShowError = true
        this.canScroll()
        return
      }

      if (!this.getCheckboxs.length) {
        // console.log(this.getCheckboxs)
        this.isFilterShowWarning = true
        this.noScroll()
        return
      }

      // if(this.getCheckboxs.length !==)
      this.getCheckboxs.forEach(item => {
        this.flag = true
        // console.log(item, '0')
        // console.log(this.details.Detail)
        const { FilterName, FilterDescription, Sequence } = this.details.Detail[
          item - 1
        ]
        const Quantity = this.num[item - 1].i
        // console.log(this.OrderDetail)
        // console.log(this.details.Detail[item - 1].FilterName)
        if (Quantity > 0 && Quantity <= 99) {
          this.OrderDetail.push({
            FilterName,
            FilterDescription,
            Quantity,
            Sequence
          })
          this.flag = true
        } else if (Quantity <= 0) {
          this.isFilterShowWarning = true
          this.noScroll()
          this.flag = false
          return true
        }
      })
      if (!this.flag) {
        return
      }
      if (!this.startTime.time) {
        this.isShowTwo3 = true
        this.noScroll()
        return
      }
      const filterOrder = {
        ModelType: this.ModelType,
        CustId: this.userName.CustId,
        CustName: this.userName.CustName,
        CustMobile: this.userName.Mobile,
        CustAddress: this.userName.CustAddress,
        DistributionId: this.list.CRMid,
        DistributionUofId: this.list.UOFid,
        // TODO: 櫻花要求將經銷商編號寫死
        // DistributionUofId: 'AAP999',
        ProductId: this.CustProdId,
        BookingType: this.nameId,
        BookingDate: this.startTime.time,
        OrderDetail: this.OrderDetail
      }

      this.isShowLoadging = true
      try {
        const res = await ajaxPost(
          'https://crmreport.sakura.com.tw/API/CreateFilterOrder',
          filterOrder
        )
        if (res.State) {
          // if (this.btnName === '確定') {
          if (this.btnNameType === '1') {
            this.isShowTwo = true
            this.noScroll()
          } else {
            this.isShowTwo2 = true
            this.noScroll()
          }
          this.isShowLoadging = false
        } else {
          throw res
        }
      } catch (error) {
        this.modyType = '5'
        this.isShowError = true
        this.noScroll()
        this.isShowLoadging = false
      }
    },
    noShowClick () {
      this.isShowTwo = false
      this.canScroll()
      // this.btnName = '更正'
      this.btnNameType = '2'
      this.OrderDetail = []
    },
    _noShowClick () {
      this.isShowTwo2 = false
      this.canScroll()
      this.OrderDetail = []
    },
    // 獲取寬度
    handleResize (event) {
      this.fullWidth = document.documentElement.clientWidth
      if (this.fullWidth <= 767) {
        // document.documentElement.scrollTop = 380
        // this.$refs.refSuccess.scrollTo(0, 500)
        // this.$refs.result.refSuccess.scrollIntoView()
      }
    },
    handleScroll (e) {
      // console.log('e', e.srcElement.scrollTop)
      // const scrolltop = document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrolltop)
      // let top = document.documentElement.scrollTop || document.body.scrollTop
      // document.body.scrollTop = document.documentElement.scrollTop = -525
      // document.documentElement.scrollTop = document.body.scrollTop = 100
      if (this.fullWidth <= 767) {
        // alert('222')
        /*  console.log('scc', document.documentElement.scrollTop)
        this.$refs.refSuccess.scrollTo = 0 */
      }
    },
    isShowClick (E) {
      // const { FilterName, FilterDescription } = E
      // this.OrderDetail.push({ FilterName, FilterDescription })
      // console.log('4', this.OrderDetail)
      // console.log(FilterName, FilterDescription)
      // console.log('1', this.getCheckboxs)
      // console.log('2', this.num)
      // console.log('3', E)
    },
    // 尚未選擇品項與數量
    isFilterWarningClick () {
      this.isFilterShowWarning = false
      this.canScroll()
    },
    // 尚未選擇時間
    noWarningClick () {
      this.isShowTwo3 = false
      this.canScroll()
    },
    closeHolidayClick () {
      this.isShowError = false
      this.canScroll()
    }
  }
}
</script>

<style scoped lang="scss">
.price-row {
  padding: 0.5rem 0 1.5rem 0;
  font-size: 14px;
}
.content {
  .el-card {
    border-radius: 1rem;
    padding: 0.5rem 0 1.2rem 0;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      .title_left {
        display: flex;
        align-items: flex-end;
        h1 {
          font-size: 2rem;
          color: rgba(61, 61, 61, 1);
          font-weight: 700;
          margin-right: 0.6rem;
        }
        span {
          font-size: 1.2rem;
          font-weight: lighter;
          color: rgba(189, 189, 189, 1);
        }
      }
      .title_right {
        font-size: 1.2rem;
        color: #c8161d;
      }
    }
    .list {
      .el-row:nth-of-type(2n) {
        background-color: rgba(249, 249, 250, 1);
      }
      .el-row:nth-of-type(2n-1) {
        background-color: #eaeaea;
      }
      .input-checkbox {
        label {
          display: flex;
          align-items: center;
          padding: 1rem 0;
        }
        span {
          margin-right: 0.6rem;
        }
      }
      .remmber {
        display: none;
      }
      .remmber[type='checkbox'] + span {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        background: url('./../../../assets/icon/round.png') no-repeat;
        background-size: 1.6rem;
      }
      .remmber[type='checkbox']:checked + span {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        background: url('./../../../assets/icon/mes.png') no-repeat;
        background-size: 1.6rem;
      }
      .el-row {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        border-radius: 1rem;
        .el-col {
          height: 4.8rem;
          display: flex;
          align-items: center;
          padding-left: 1.5rem;
          span {
            color: rgba(61, 61, 61, 1);
            i {
              margin-right: 0.8rem;
              color: #c8161d;
            }
          }
          .item {
            display: flex;
            align-items: center;
            img {
              width: 1.4rem;
              height: 1.4rem;
              margin-right: 0.6rem;
            }
            .name {
              display: flex;
              flex-direction: column;
              span:last-of-type {
                margin-top: 0.5rem;
                font-size: 1rem;
                color: rgba(134, 134, 134, 1);
              }
            }
            // span:last-of-type {
            //   margin-left: .5rem;
            //   font-size: 1rem;
            //   color: rgba(134, 134, 134, 1);
            // }
          }
          .btn {
            position: relative;
            width: 9.2rem;
            height: 3rem;
            border-radius: 0.6rem;
            border: 0.1rem solid rgba(3, 187, 0, 1);
            text-align: center;
            .bg_color {
              position: absolute;
              height: 100%;
              width: 13%;
              background: rgba(3, 187, 0, 0.15);
            }
            span {
              color: rgba(3, 187, 0, 1);
              line-height: 3rem;
            }
            &.red {
              border-color: #b22128;
              overflow: hidden;
              .bg_color {
                width: 0%;
                background-color: #fff;
              }
              span {
                color: #b22128;
              }
            }
          }
          .amount {
            color: rgba(134, 134, 134, 1);
            select {
              width: 4rem;
              height: 2rem;
              border: none;
              font-size: 1.6rem;
              // outline: none;
              background-color: #fff;
              margin-left: 0.3rem;
              padding-left: 0.5rem;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
            }
            span {
              // padding: .6rem 2rem .6rem .8rem;
            }
          }
        }
      }
    }
    .bottom {
      margin-top: 2rem;
      .tiem {
        display: flex;
        align-items: center;
        border: 1px solid rgba(215, 216, 223, 1);
        border-radius: 0.6rem;
        font-size: 1.2rem;
        color: rgba(61, 61, 61, 1);
        padding: 0.7rem 0.6rem;
        .tex {
          // flex: 1;
          width: 40%;
        }
        .tiems {
          height: 100%;
          width: 60%;
          // flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .cov-vue-date {
            height: 100%;
            width: 100%;
            .datepickbox {
              width: 100%;
              height: 100%;
            }
          }
          span {
            color: rgba(189, 189, 189, 1);
          }
        }
      }
      .btn {
        margin-left: 3rem;
        padding: 1rem;
        font-size: 1.4rem;
        color: #fff;
        cursor: pointer;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10rem;
          height: 3rem;
          border-radius: 0.6rem;
          background: #c8161d;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .title {
      align-items: flex-end;
      .title_left {
        text-align: center;
        flex-direction: column !important;
        align-items: flex-start !important;
        span {
          margin-top: 0.6rem;
        }
      }
    }
    .list {
      .el-row {
        flex-wrap: wrap;
        .el-col:nth-of-type(4),
        .el-col:nth-of-type(5) {
          margin-top: -0.8rem;
        }
        .el-col:nth-of-type(5) {
          display: flex;
          justify-content: flex-end;
        }
        .amount {
          margin-right: 2.2rem;
        }
      }
    }
    .bottom {
      padding: 0 10%;
      .row {
        .row_col {
          margin-bottom: 1.5rem;
        }
        .bottom_btn {
          margin-top: 1.5rem;
          margin-right: 1rem;
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    /deep/ .el-card__body {
      padding: 2rem 1rem;
    }
    .list {
      .el-row {
        .el-col {
          padding-left: 1.2rem !important;
          span {
            i {
              margin-right: 0.3rem !important;
            }
          }
        }
      }
    }
    .bottom {
      padding: 0 10% !important;
      .row {
        .bottom_btn {
          margin-top: 1.5rem !important;
          margin-left: auto !important;
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .bottom {
      padding: 0 8% !important;
    }
    /*  .bottom {
    padding: 0 20%!important;

    .row {
      padding: 0 20%!important;
      .el-row {
         box-sizing: border-box;

        }

    }
    } */
  }
  @media screen and (max-width: 540px) {
    .list {
      .el-row {
        .el-col:nth-of-type(1) {
          width: 45%;
        }
        .el-col:nth-of-type(2) {
          width: 55%;
        }
        .el-col:nth-of-type(3) {
          width: 100%;
          margin-top: -0.8rem;
        }
        .el-col {
          .amount {
            input {
              width: 5rem !important;
            }
          }
        }
      }
    }
    .bottom {
      .btn {
        position: relative;
        margin-bottom: 1rem;
        span {
          position: absolute;
          top: 0;
          left: -2.5rem;
        }
      }
    }
  }
  @media screen and (max-width: 409px) {
    .list {
      .el-row {
        .el-col:nth-of-type(1) {
          width: 40%;
        }
        .el-col:nth-of-type(2) {
          width: 60%;
        }
        .el-col:nth-of-type(3) {
          width: 100%;
          margin-top: -0.8rem;
        }
      }
    }
    .bottom {
      .btn {
        position: relative;
        margin-bottom: 1rem;
        span {
          position: absolute;
          top: 0;
          left: -2.5rem;
        }
      }
    }
  }
}
</style>
